.site-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;
    color: #000;
    .wrapper {
        width: 1140px;
        margin: 0 auto;
        padding: 15px 0;
        text-align: center;
        font-size: 14px;
        p {
            margin: 0;
            line-height: 1.6;
        }
        .p-mono{
            a{
                font-family: monospace;
                color: #ccc;
                transition: 0.3s ease;
                &:hover {
                    color: #000;
                }
                i {
                    margin: 0 5px;
                    font-size: 20px;
                }
            }
        }
        .contact {
            display: inline-block;
            
            a {
                color: #ccc;
                transition: 0.3s ease;
                &:hover {
                    color: #000;
                }
                i {
                    margin: 0 5px;
                    font-size: 20px;
                }
            }
        }
        .description {
            display: inline-block;
        }
        .power {
            a {
                color: #8F90C5;
                transition: 0.3s ease;
                &:hover {
                    color: #fff;
                }
            }
        }
        .stat-text{
          color: #75715e;
        }

        .BY-NC-ND{
           color: #000;
           &:hover {
                    color: #000;
                }
        }

    }
}

@media screen and (max-width:1200px) and (min-width: 770px) {
    .site-footer {
        .wrapper {
            width: 90%;
        }
    }
}
@media screen and (max-width: 770px) {
    .site-footer {
        .wrapper {
            width: 90%;
        }
    }
}
