body,
html,
div,
header,
footer,
nav,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}
html {
    height: 100%;
}
body {
    // font-family: 'Helvetica Neue',Helvetica,Arial,'Microsoft YaHei',sans-serif;
    font-family: -apple-system,SF UI Text,Arial,PingFang SC,Hiragino Sans GB,Microsoft YaHei,WenQuanYi Micro Hei,sans-serif;
    color: #333;
    position: relative;
    min-height: 100%;
    padding-bottom: 190px;
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
a {
    text-decoration: none;
}
button {
    border: none;
    outline: none;
}
hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
}

///////////
h1 {
    font-size: 30px;
}
h2 {
    font-size: 26px;
    border-bottom: 1px solid #eee;
    margin: 50px 0 25px;
}
h3 {
    font-size: 23px;
    margin: 50px 0 20px;
}
h4 {
    font-size: 19px;
    margin: 30px 0 10px;
}
h5 {
    font-size: 17px;
    margin: 20px 0 10px;
}
h6 {
    font-size: 16px;
    margin: 10px 0 5px;
}
blockquote {
    color: #444;
    border-left: 5px solid #D6DBDF;
    padding-left: 15px;
    background: rgba(112,138,153,.1);
    padding: 10px 10px;
    margin: 0 0 10px;
    p:last-of-type{
        margin: 0;
    }
}
table {
    
    margin: 8px 0;
    border-collapse: collapse;
    thead {
        border-bottom: 1px solid #777;
        background-color: #aaa;
        color: #fff;
    }
    th {
        padding: 2px 10px;
        text-align: left;
    }
    // tbody {
    //     tr {
    //         &:nth-of-type(2n+1) {
    //             background-color: #f8f8fd;
    //         }
    //         &:nth-of-type(2n) {
    //             background-color: #e5eaed;
    //         }
    //     }
    // }
    td {
        padding: 2px 10px;
    }
}
p {
    margin: 0 0 10px;
}
img {
    max-width: 100%;
}

.gist .gist-meta {
    display: none;
}
.gist .gist-data{
    border-bottom: none !important;
}
.label-hoo{
    color: #fff;
    font-size: 8px;
    max-height: 10px;
}
.label-yes{
    display:none;
    color: #fff;
}