.back-to-top {
    position: fixed;
    left: 50%;
    margin-left: 580px;
    bottom: 37px;
    background-color: #f8f8fd;
    visibility: hidden;
    opacity: 0;
    transition: 0.4s ease;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3), 0 0 30px rgba(10, 10, 0, 0.1) inset;
    a {
        display: block;
        background-color: #eee;
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        font-size: 12px;
        color: #b4b4b4;
    }
}
.back-to-top-show{
    visibility: visible;
    opacity: 0.7;
    &:hover{
        opacity: 1
    }
}
@media screen and (max-width:1200px) and (min-width: 770px) {
    .back-to-top {
        left: 50%;
        margin-left: 45.5%;
    }
}
@media screen and (max-width: 770px) {
    .back-to-top {
        right:2.5%;
        left: auto;
        // left: 50%;
        margin-left: 0px;
        a {
            width: 33px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            font-size: 12px;
        }
    }
    .back-to-top-show{display: none;}
    .post-recent{display: none;}
}
