header {
    height: 52px;
    color: #9d9d9d;
    margin-bottom: 20px;
    a {
        color: #9d9d9d;
        -webkit-transition: 0.2s ease;
        transition: 0.2s ease;
        &:hover {
            color: #000;
        }
    }
    .wrapper {
        width: 1140px;
        margin: 0 auto;
        line-height: 52px;
        .brand {
            font-size: 18px;
        }
        small {
            margin-left: 26px;
            font-size: 14px;
        }
        .menu{
            display: none;
        }
        nav {
            float: right;
            ul {
                font-size: 0;
                margin: 0;
                padding: 0;
                li {
                    list-style: none;
                    font-size: 14px;
                    display: inline-block;
                    -webkit-transition: 0.2s ease;
                    transition: 0.2s ease;
                    a {
                        display: inline-block;
                        padding: 0 10px;
                        height: 52px;
                        &:hover {
                           
                        }
                        i {
                            margin-right: 5px;
                        }
                    }
                    >.active {
                        color: #000;
                       
                        
                    }
                }
            }
        }
    }
}

@media screen and (max-width:1200px) and (min-width: 770px) {
    header {
        height: 50px;
        .wrapper {
            width: 90%;
            line-height: 50px;
            .brand {
                font-size: 16px;
            }
            small {
                margin-left: 15px;
                font-size: 12px;
            }
            .menu{
                display: none;
            }
            nav {
                ul {
                    li {
                        a {
                            padding: 0 8px;
                            height: 50px;
                            i {
                                margin-right: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 770px) {
    header {
        height: 48px;
        margin-bottom: 15px;
        background-color: #333;
        .wrapper {
            width: 95%;
            margin: 0 auto;
            line-height: 48px;
            position: relative;
            z-index: 101;
            .brand {
                font-size: 16px;
                font-weight: bold;
                text-transform: uppercase; 
                color: #fff;
            }
            small {
                margin-left: 10px;
                font-size: 12px;
            }
            .menu{
                display: inline-block;
                position: absolute;
                z-index: -102;
                right: 0;
                top:10px;
                padding: 5px 10px;
                background: #333;
                border: 1px solid #333;
                border-radius: 4px;
                color: #c9c9c9;
                font-size: 16px;
                -webkit-transition: 0.4s ease;
                transition: 0.4s ease;
            }
            >.active{
                background: #000;
                color: #fff;
            }
            .nav-show{
                visibility: visible;
                transform: scale(1);
                opacity: 1;

            }
            nav {
                position: absolute;
                z-index: 100;
                right:0;
                top:47px;
                text-align: center;

                visibility: hidden;
                transform-origin: 100% 0%;
                transform: scale(0.1);
                opacity: 0;
                transition: 0.2s cubic-bezier(0.5, 0.1, 0.51, 1.38);
                ul {
                    background: #222;
                    border-radius: 4px 4px 4px 4px;
                    box-shadow: 0px 3px 4px 1px rgba(0,0,0,0.5);
                    li {
                        font-size: 14px;
                        display: block;
                        text-align:left;
                        border-bottom: 1px solid #333;
                        a {
                            display: block;
                            padding: 0 10px;
                            height: 40px;
                            line-height: 40px;
                        }
                        &:last-of-type{
                            border: none;
                        }
                        >.active {
                            color: #fff;
                            background-color: #000;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }
}

@media  (prefers-color-scheme: dark)  {
    body { background-color: #333; color: white; }
    blockquote{
        color: #fff !important;
    }
    .page[index] .left > ul li{
        color: #fff !important;
    }
    .page[index] h3 a{
        color: #fff !important;
    }
    .ee{
        color: #fff !important;;
    }
    .label-hoo{
        color: #333;
        font-size: 8px;
        max-height: 10px;
    }
    .label-yes{
        display:none;
        color: #333;
    }
    .page .left ul li .title{
        color:#4d6dad !important;
    }
    .page .left ul li .categories a{
        color:#888888 !important;
    }
    .page .left ul li .pageTag a{
        background-color: #313131 !important;
    }
    .page[post] .left ul li{
        color:#888888 !important;
    }
    p code.highlighter-rouge{
        color: #fff;
    }
    header .wrapper nav ul li > .active{
        color: #fff;
    }
}

@media (prefers-color-scheme: dark) and (max-width: 770px){
    body {
        background-color: #333;
        color: white;
    }
    header {
        height: 48px;
        margin-bottom: 15px;
        background-color: #000 !important;
        .menu{
            background: #000 !important;
            border: 1px solid #000 !important;
        }
    }
    blockquote{
        color: #fff !important;
    }
    .page[index] .left > ul li{
        color: #fff !important;
    }
    .ee{
        color: #fff !important;;
    }
    .label-hoo{
        color: #333;
        font-size: 8px;
        max-height: 10px;
    }
    .label-yes{
        display:none;
        color: #333;
    }
    .page .left ul li .title{
        color:#4d6dad !important;
    }
    .page .left ul li .categories a{
        color:#888888 !important;
    }
    .page .left ul li .pageTag a{
        background-color: #313131 !important;
    }
    .page[post] .left ul li{
        color:#888888 !important;
    }
    p code.highlighter-rouge{
        color: #fff;
    }
}