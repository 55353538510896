.page[index] {
    a {
        color: #4d6dad;
        transition: 0.5s ease;
        &:hover {
            color: #223253;
        }
    }
    .none{
        display: none;
    }
    .left {
        >ul {
            padding: 0;
            >li {
                list-style: none;
            }
            li {
                color: #333;
                h2 {
                    border: none;
                    font-size: 27px;
                    margin: 0;
                }
                .label {
                    margin-bottom: 15px;
                    overflow: hidden;
                    .label-card {
                        float: left;
                        margin-right: 15px;
                        i {
                            padding-right: 0;
                            margin-right: 3px;
                            color: #6b6a6a;
                        }
                    }
                    .categories {
                        padding: 0;
                    }
                }
                .excerpt {
                    h2 {
                        font-size: 24px;
                    }
                    h3 {
                        font-size: 22px;
                    }
                    h4 {
                        font-size: 20px;
                    }
                }
                .read-all {
                    text-align: right;
                    font-size: 14px;
                    a {
                        color: #778ab0;
                        transition: 0.2s ease;
                        &:hover {
                            color: #5872a7;
                        }
                        i {
                            padding-right: 3px;
                        }
                    }
                }
            }
        }
        .pagination {
            text-align: center;
            .previous,
            .next {
                font-size: 16px;
                padding: 0 5px;
            }
            .page_number {
                padding: 0 5px;
            }
            .disable {
                color: #cbcbcb;
            }
        }
    }
    .right {
        .wrap {
            .side {
                margin-bottom: 20px;
                padding-bottom: 0;
                i {
                    margin-right: 3px;
                }
                .content-ul[cate] {
                    overflow: hidden;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    li {
                        font-size: 14px;
                        a {
                            display: block;
                            width: 100%;
                            padding: 2px 0;
                            border-bottom: 1px solid #eee;
                            &:hover {
                                background-color: #dbdfee;
                                .badge {
                                    background-color: #dbdfee;
                                    transform: scale(1.2) rotate(360deg);
                                }
                            }
                            .name {
                                padding-left: 15px;
                            }
                            .badge {
                                display: block;

                                // width: 20px;
                                float: right;
                                text-align: center;
                                margin-right: 15px;
                                margin-top: 3px;
                                padding: 0 10px;
                                font-size: 12px;
                                color: #fff;
                                background-color: #bbb;
                                border-radius: 50%;
                                transition: 0.5s ease-in-out;
                            }
                        }
                        &:last-of-type a {
                            border: none;
                        }
                    }
                }
                .content-ul[recent]{
                    padding-bottom: 16px;
                    li{
                        color:#aaa;
                        font-size: 14px;
                    }
                }
                .tags-cloud {
                    font-weight: normal;
                    a {
                        padding: 0 3px;
                        border-radius: 5px;
                        &:hover {
                            background-color: #f0e7ce;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 770px) {
    .page[index] {
        .left>ul li {
            h2 {
                font-size: 22px;
            }
            .excerpt {
                h2 {
                    font-size: 20px;
                }
                h3 {
                    font-size: 18px;
                }
                h4 {
                    font-size: 16px;
                }
                li{
                    margin: 0;
                }
            }
        }
    }
}
