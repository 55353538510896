/**
 * Syntax highlighting styles
 */
code{
    padding: 1px 4px;
    margin: 0 2px;
    font-size: 90%;
    border-radius: 3px;
    color: #000;
    background-color: rgba(0, 0, 0, 0.06);
    border: 1px solid #d7d0d2;
    font-family: Monaco,Menlo,"Microsoft YaHei Mono",Consolas,"Courier New",monospace,sans-serif;
}

pre {
    margin: 12px 0;
     padding: 8px 12px;
    //  overflow-x: auto;
     word-wrap: break-word;      /* IE 5.5-7 */
     white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
     white-space: pre-wrap;      /* current browsers */
     > code {
        margin: 0;
        padding: 0;
        font: 14px Consolas, "Liberation Mono", Menlo, Courier, monospace;
        border: none;
        background-color:transparent;
     }
}

.highlight {
    border-radius: 3px;

    background: #f7f7f7;
    .hll { background-color: #272822; }
    .c { color: #75715e } /* Comment */
    .err { color: #960050;  } /* Error */
    .k { color: #66d9ef } /* Keyword */
    .l { color: #ae81ff } /* Literal */
    .n { color: #f8f8f2 } /* Name */
    .o { color: #f92672 } /* Operator */
    .p { color: #000 } /* Punctuation */
    .cm { color: #75715e } /* Comment.Multiline */
    .cp { color: #75715e } /* Comment.Preproc */
    .c1 { color: #75715e } /* Comment.Single */
    .cs { color: #75715e } /* Comment.Special */
    .ge { font-style: italic } /* Generic.Emph */
    .gs { font-weight: bold } /* Generic.Strong */
    .kc { color: #66d9ef } /* Keyword.Constant */
    .kd { color: #66d9ef } /* Keyword.Declaration */
    .kn { color: #f92672 } /* Keyword.Namespace */
    .kp { color: #66d9ef } /* Keyword.Pseudo */
    .kr { color: #66d9ef } /* Keyword.Reserved */
    .kt { color: #66d9ef } /* Keyword.Type */
    .ld { color: #e6db74 } /* Literal.Date */
    .m { color: #ae81ff } /* Literal.Number */
    .s { color: #e6db74 } /* Literal.String */
    .na { color: #a6e22e } /* Name.Attribute */
    .nb { color: #005cc5 } /* Name.Builtin */
    .nc { color: #a6e22e } /* Name.Class */
    .no { color: #66d9ef } /* Name.Constant */
    .nd { color: #a6e22e } /* Name.Decorator */
    .ni { color: #f8f8f2 } /* Name.Entity */
    .ne { color: #a6e22e } /* Name.Exception */
    .nf { color: #a6e22e } /* Name.Function */
    .nl { color: #f8f8f2 } /* Name.Label */
    .nn { color: #f8f8f2 } /* Name.Namespace */
    .nx { color: #6f42c1 } /* Name.Other */
    .py { color: #f8f8f2 } /* Name.Property */
    .nt { color: #f92672 } /* Name.Tag */
    .nv { color: #f8f8f2 } /* Name.Variable */
    .ow { color: #f92672 } /* Operator.Word */
    .w { color: #f8f8f2 } /* Text.Whitespace */
    .mf { color: #ae81ff } /* Literal.Number.Float */
    .mh { color: #ae81ff } /* Literal.Number.Hex */
    .mi { color: #ae81ff } /* Literal.Number.Integer */
    .mo { color: #ae81ff } /* Literal.Number.Oct */
    .sb { color: #e6db74 } /* Literal.String.Backtick */
    .sc { color: #e6db74 } /* Literal.String.Char */
    .sd { color: #e6db74 } /* Literal.String.Doc */
    .s2 { color: #e6db74 } /* Literal.String.Double */
    .se { color: #ae81ff } /* Literal.String.Escape */
    .sh { color: #e6db74 } /* Literal.String.Heredoc */
    .si { color: #e6db74 } /* Literal.String.Interpol */
    .sx { color: #e6db74 } /* Literal.String.Other */
    .sr { color: #e6db74 } /* Literal.String.Regex */
    .s1 { color: #e6db74 } /* Literal.String.Single */
    .ss { color: #e6db74 } /* Literal.String.Symbol */
    .bp { color: #f8f8f2 } /* Name.Builtin.Pseudo */
    .vc { color: #f8f8f2 } /* Name.Variable.Class */
    .vg { color: #f8f8f2 } /* Name.Variable.Global */
    .vi { color: #f8f8f2 } /* Name.Variable.Instance */
    .il { color: #ae81ff } /* Literal.Number.Integer.Long */
    .gh { } /* Generic Heading & Diff Header */
    .gu { color: #75715e; } /* Generic.Subheading & Diff Unified/Comment? */
    .gd { color: #f92672; } /* Generic.Deleted & Diff Deleted */
    .gi { color: #a6e22e; } /* Generic.Inserted & Diff Inserted */
}

.language-js,.language-javascript {
  .kd { color: #d73a49}
  .k { color: #d73a49}
  .na{ color: #242424}
  .s1,.s2{color: #FF6633}
  .c1{color:#690}
  .nl{color:#6f42c1}

}
.language-js .highlight{
    background-color: #fff;
}
.language-js pre.highlight{
    border: 1px dashed #abbdab;
}
.language-go{
    .c{color: #6a737d;}
    .n { color: #6f42c1}
    .k { color: #d73a49}
    .na{ color: #242424}
    .s1,.s2{color: #FF6633}
    .c1{color:#690}
    .nl{color:#6f42c1}
    .s{color: #032f62;}
    
  }
  .language-go .highlight{
      background-color: #fff;
  }
  .language-go pre.highlight{
      border: 1px dashed #abbdab;
  }

  .language-sql{
    .c{color: #6a737d;}
    .n { color: #6f42c1}
    .k { color: #d73a49}
    .na{ color: #242424}
    .s1,.s2{color: #FF6633}
    .c1{color:#690}
    .nl{color:#6f42c1}
    .s{color: #032f62;}
    
  }
  .language-sql .highlight{
      background-color: #fff;
  }
  .language-sql pre.highlight{
      border: 1px dashed #abbdab;
  }

  .language-java{
    .c{color: #6a737d;}
    .n { color: #6f42c1}
    .k { color: #d73a49}
    .kd{color:#f92672}
    .na{ color: #242424}
    .nc,.nf{color: #272822;}
    .nn{ color: #242424}
    .s1,.s2{color: #FF6633}
    .c1{color:#690}
    .nl{color:#6f42c1}
    .o{color:#242424 ;}
    .s{color: #032f62;}
    
  }
  .language-java .highlight{
      background-color: #fff;
  }
  .language-java pre.highlight{
      border: 1px dashed #abbdab;
  }