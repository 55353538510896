.content-ul {
    &::-webkit-scrollbar {
        width: 7px;
        height: 6px;
    }
    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    &::-webkit-scrollbar-thumb {
        background: #D3D3D3;
        border: 0 none #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
    &::-webkit-scrollbar-thumb:active {
        background: #787878;
    }
    &::-webkit-scrollbar-track {
        background: #F1F1F1;
        // border: 1 solid #ffffff;
        border-radius: 50px;
    }
    &::-webkit-scrollbar-track:hover {
        background: #e2e2e2;
    }
    &::-webkit-scrollbar-track:active {
        background: #cccccc;
    }
    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}
